/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Mon Apr 25 2022
 */
import XLSXStyle from 'xlsx-style'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver';

export const createWorkbook = () => {
  return XLSX.utils.book_new()
}

export const createWorksheet = () => {
  return {}
}

export const bookAppendSheet = (workbook, sheet, sheetName) => {
  XLSX.utils.book_append_sheet(workbook, sheet, sheetName)
}

export const aoaToSheet = (aoa) => {
  return XLSX.utils.aoa_to_sheet(aoa)
}

export const downloadExcelWithStyle = (workbook, name) => {
  var wopts = { bookType:'xlsx', bookSST:false, type:'binary' }
  var wbout = XLSXStyle.write(workbook, wopts)
  function s2ab (s) {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }
  saveAs(new Blob([s2ab(wbout)],{type:""}), `${name ? name : workbook.SheetNames[0]}.xlsx`)
}

export class Colspan {
  constructor (cols, row) {
    return cols.map(col => {
      return {s: {c: col[0], r: row}, e: {c: col[1], r: row}}
    })
  }
}

export class Rowspan {
  constructor (rows, col) {
    return rows.map(row => {
      return {s: {c: col, r: row[0]}, e: {c: col, r: row[1]}}
    })
  }
}

export const colspan = (num, empty = '__colspan') => new Array(num).fill(empty)

export const rowspan = (num, empty = '__rowspan') => new Array(num).fill(empty)

export const eachWorkbook = (workbook, callback) => {
  workbook.SheetNames.forEach(sheetName => {
    const range = XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref'])
    for(let R = range.s.r; R <= range.e.r; ++R) {
      for(let C = range.s.c; C <= range.e.c; ++C) {
        var cell_address = {c:C, r:R};
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!workbook.Sheets[sheetName][cell_ref]) {
          workbook.Sheets[sheetName][cell_ref] = {v: ''}
        }
        callback(workbook.Sheets[sheetName][cell_ref], sheetName, cell_ref, R, C)
      }
    }
  })
}

export const setWorkbookBaseStyle = (workbook, styles) => {
  eachWorkbook(workbook, (cell) => {
    cell.s = Object.assign({
      font: {
        name: '宋体',
        sz: 12
      },
      alignment: {
        horizontal: 'center',
        vertical: 'center',
        wrapText: true
      },
      border: {
        top: {style: 'thin', color: {rgb: '000000'}},
        bottom:	{style: 'thin', color: {rgb: '000000'}},
        left:	{style: 'thin', color: {rgb: '000000'}},
        right: {style: 'thin', color: {rgb: '000000'}}
      }
    }, styles)
  })
}

export const setWorksheetCellStyle = (worksheet, cells = [], styles) => {
  cells.forEach(cell => {
    const ref = XLSX.utils.encode_cell({r: cell[0], c: cell[1]})
    if (!worksheet[ref]) {
      worksheet[ref] = {v: '', s: undefined}
    }
    worksheet[ref].s = Object.assign(
      worksheet[ref].s || {},
      styles
    )
  })
}